import React from "react"
import Layout from "../components/Layout"
import big from "../assets/images/bgmain.jpg"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

//para espacio de spacer hacer pequeno el background
const spacer = 20

//para agrandamiento de segundo porcentaje de background
const percentage = 200

const Franja = styled.div`
  background: black;
  height: 60px;
`

const TituloBox = styled.div`
  font-size: 38px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  color: white;
`
const MensajeBox = styled.div`
  font-size: 58px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`

const LeftBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`

const RightBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  /* letter-spacing: -2px; */
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`
const Boldie = styled.div`
  font-weight: bold;
`

const QuienesSomos = () => {
  return (
    <Layout sbiggo={big} spacer={spacer} percentage={percentage}>
      <Franja>
        <Container>
          <Row>
            <Col>
              <TituloBox>Quiénes Somos</TituloBox>
            </Col>
          </Row>
        </Container>
      </Franja>
      <Container>
        <Row>
          <Col>
            <MensajeBox>
              Generamos soluciones de calidad y creamos relaciones de negocio a
              largo plazo
            </MensajeBox>
          </Col>
        </Row>
      </Container>
      <StaticImage
        src="../assets/images/quienes.jpg"
        alt="web"
        aspectRatio={26 / 9}
      />
      <Container>
        <Row>
          <Col md={3} className="d-none d-lg-block">
            <LeftBox>
              <Boldie>Contáctenos: </Boldie>
              <br /> info@yaku.com.ec <br />
              +593999944405
            </LeftBox>
          </Col>
          <Col md={9} xs={12} className="d-lg-block">
            <RightBox>
              Yaku es una empresa ecuatoriana que se dedica a crear soluciones
              tecnológicas para negocios en la Internet mediante la aplicación
              de conocimiento y experiencia.
              <br /> <br />
              Mantenemos una amplia cartera de clientes que han confiado en
              nuestro trabajo. Hemos creado sitios web, aplicaciones web,
              aplicaciones sociales, entre otros. <br />
              <br />A través de los años hemos incrementado el uso de nuevas
              tecnologías que le permitan a nuestros clientes poder solventar
              cualquier requerimiento sin importar el grado de complejidad.
              <br />
              <br />
              Hemos trabajado con empresas líderes del mercado en el sector
              privado y también en proyectos del sector público. <br />
              <br />
              ¿Tiene un nuevo proyecto? Permítanos ayudarle a conseguir los
              objetivos planificados. Nuestro soporte es personalizado, somos
              parte de su equipo y nos comprometemos a obtener las metas de cada
              proyecto. <br />
              <br />
              <br />
              <Boldie>Experiencia</Boldie>
              <br />
              <br />
              En Yaku utilizamos tecnología probada y de tendencia en el
              mercado. Nuestras aplicaciones cumplen con tres requisitos
              esenciales: alta disponibilidad, eficiencia y escalabilidad.
              Generamos relaciones de negocio a largo plazo con nuestros
              clientes. A lo largo de los proyectos realizados se han
              establecido lazos de confianza. <br />
              <br />
              Hemos trabajado en proyectos de alta complejidad para empresas
              líderes del sector legal, educativo, turismo y otras tanto en el
              sector público como privado, contáctese con nosotros y con gusto
              le enviaremos nuestro brochure empresarial. <br />
              <br />
              <br />
              <Boldie>Nuestro Equipo</Boldie>
              <br />
              <br /> Nuestro equipo de profesionales está preparado para brindar
              el soporte técnico necesario para su proyecto, nuestras
              habilidades son desarrollo tecnológico, marketing estratégico,
              diseño gráfico.
            </RightBox>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default QuienesSomos
